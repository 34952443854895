import React from "react"
import "./style.scss"

const PcpSection = ({ title, text }) => {
  return (
    <div className="pcp-section"> 
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </div>
  )
}

export default PcpSection
